import { ABOUT_US } from '~/scopes/about-us/routes.constants'
import { CMS } from '~/scopes/cms/routes-names'
import { DISCOUNT } from '~/scopes/discount/routes-names'
import { HELP_CENTER } from '~/scopes/help-center/routes'
import { LEGAL_PAGE_NAME } from '~/scopes/legal/constants'
import { LEGAL } from '~/scopes/legal/routes'
import { MODELS_COMPARISON } from '~/scopes/models-comparison/routes-names'

import translations from '../Footer.translations'
import type { FooterLink, FooterLinkInternal } from '../FooterContent'

const footerLinks = {
  ABOUT_US: {
    key: translations.aboutUs,
    name: ABOUT_US,
    type: 'link-internal',
  },
  ACCESSIBILITY: {
    key: translations.accessibilityStatement,
    name: CMS.STATEMENT,
    type: 'link-internal',
  },
  MODELS_COMPARISON: {
    key: translations.modelsHomeComparison,
    name: MODELS_COMPARISON.HOME,
    prefetch: false,
    type: 'link-internal',
  },
  GIFT_FINDER: {
    key: translations.giftFinder,
    name: CMS.GIFT_FINDER_MAIN,
    type: 'link-internal',
    params: {
      pageName: 'finder',
    },
  },
  BACK_STORIES: {
    key: translations.backStories,
    name: CMS.BACK_STORIES,
    type: 'link-internal',
    params: {
      pageName: 'home',
    },
  },
  BUY_BACK: {
    key: translations.buyback,
    name: CMS.BUYBACK,
    params: {
      pageName: 'home',
    },
    type: 'link-internal',
  },
  BUYING_GUIDES: {
    key: translations.buyingGuides,
    name: CMS.ARTICLE_ROOT_HUB,
    type: 'link-internal',
  },
  STUDENTS_GOOD_DEALS: {
    key: translations.studentGoodDeals,
    name: DISCOUNT.STUDENT,
    type: 'link-internal',
  },
  MILITARY_PROGRAM: {
    key: translations.militaryProgram,
    name: DISCOUNT.MILITARY,
    type: 'link-internal',
  },
  BACKMARKET_WARRANTY: {
    key: translations.backmarketWarranty,
    name: CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.WARRANTY,
    },
    type: 'link-internal',
  },
  REFER_FRIEND: {
    key: translations.referFriend,
    name: CMS.REFER_FRIEND,
    type: 'link-internal',
  },
  BO_MERCHANT: {
    key: translations.boMerchant,
    url: '/bo-seller',
    type: 'link-external',
  },
  HELP: {
    key: translations.help,
    name: HELP_CENTER.HOME,
    type: 'link-internal',
  },
  INSURANCES: {
    key: translations.insurances,
    name: CMS.EVENT,
    params: {
      pageName: 'backup',
    },
    type: 'link-internal',
  },
  // Category: Law
  TERMS_USE: {
    key: translations.terms,
    name: CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.TERMS_USE,
    },
    type: 'link-internal',
  },
  TERMS_SERVICE: {
    key: translations.terms,
    name: CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.TERMS_SERVICE,
    },
    type: 'link-internal',
  },
  TERMS_SALE: {
    key: translations.termsSale,
    name: CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.TERMS_SALE,
    },
    type: 'link-internal',
  },
  TERMS_BUYBACK: {
    key: translations.termsBuyback,
    name: CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.BUYBACK,
    },
    type: 'link-internal',
  },
  DATA_PROTECTION: {
    key: translations.dataProtection,
    name: CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.DATA_PROTECTION,
    },
    type: 'link-internal',
  },
  COOKIE_POLICY: {
    key: translations.cookies,
    name: LEGAL.COOKIES,
    type: 'link-internal',
  },
  CCPA: {
    key: translations.doNotSell,
    name: LEGAL.COOKIES,
    type: 'link-internal',
  },
  INFOS_CONSUMER: {
    key: translations.infosConsumer,
    name: CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.OTHER,
    },
    type: 'link-internal',
  },
  LEGAL: {
    key: translations.legal,
    name: CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.LEGAL_MENTIONS,
    },
    type: 'link-internal',
  },
  // Category: Hello

  WELCOME_TO_THE_JUNGLE: {
    key: translations.welcomeToTheJungle,
    url: 'https://www.welcometothejungle.co/fr/companies/back-market',
    type: 'link-external',
  },
  MEDIUM: {
    key: translations.medium,
    url: 'https://medium.com/@BackMarket',
    type: 'link-external',
  },
} satisfies Record<string, FooterLink | FooterLinkInternal>

const footerLinkGenerators = {
  BUY_BACK_B2B: (url: string) => ({
    key: translations.buybackB2B,
    url,
    type: 'link-external',
  }),
  HIRE: (url: string) => ({
    key: translations.hire,
    url,
    type: 'link-external',
  }),
  BLOG: (url: string) => ({
    key: translations.blog,
    url,
    type: 'link-external',
  }),
  PRESS: (url: string) => ({
    key: translations.press,
    url,
    type: 'link-external',
  }),
  IMPACT: (url: string) => ({
    key: translations.impact,
    url,
    type: 'link-external',
  }),
  PRO: (url: string) => ({ key: translations.pro, url, type: 'link-external' }),
  BECOME_REFURBISHER: (url: string) => ({
    key: translations.becomeRefurbisher,
    url,
    type: 'link-external',
  }),
  ILLEGAL_CONTENT: (url: string) => ({
    key: translations.illegalContent,
    url,
    type: 'link',
  }),
  PAYMENT: (url: string) => ({ key: translations.payment, url, type: 'link' }),
  RETURN: (url: string) => ({ key: translations.return, url, type: 'link' }),
  SHIPPING: (url: string) => ({
    key: translations.shipping,
    url,
    type: 'link',
  }),
  TRUST_PILOT: (url: string) => ({
    url,
    key: translations.trustpilot,
    type: 'link-external',
  }),
  TRUSTED_SHOPS: (url: string) => ({
    url,
    key: translations.trustedShops,
    type: 'link-external',
  }),
  GLASSDOOR: (url: string) => ({
    url,
    key: translations.glassdoor,
    type: 'link-external',
  }),
  CELEBRITY: (url: string) => ({
    key: translations.celebrity,
    url,
    type: 'link-external',
  }),
  CONTACT_US: (url: string) => ({
    key: translations.contactUs,
    url,
    type: 'link-external',
  }),
} satisfies Record<string, (url: string) => FooterLink | FooterLinkInternal>

type FooterLinks = keyof typeof footerLinks
type FooterLinkGenerators = keyof typeof footerLinkGenerators

function isFooterLinkGenerator(
  key: FooterLinks | FooterLinkGenerators,
): key is FooterLinkGenerators {
  return footerLinkGenerators[key as FooterLinkGenerators] !== undefined
}

export function getFooterLink(key: FooterLinks): FooterLink | FooterLinkInternal
export function getFooterLink(
  key: FooterLinkGenerators,
  url: string,
): FooterLink | FooterLinkInternal
export function getFooterLink(
  key: FooterLinks | FooterLinkGenerators,
  url?: string,
): FooterLink | FooterLinkInternal {
  if (isFooterLinkGenerator(key)) {
    return footerLinkGenerators[key](url as string)
  }

  return footerLinks[key]
}
