import { type I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

export type FooterContentType =
  | 'payment-networks'
  | 'payment-networks-with-link'
  | 'link'
  | 'link-internal'
  | 'link-external'
  | 'social-networks'
  | 'icons'
  | 'logos'

export type Logo =
  | 'bcorp'
  | 'bbb'
  | 'customer-service-of-the-year'
  | 'best-e-commerce-website'

export type FooterBaseContent<T> = {
  key: I18nDefinition
  type: FooterContentType
} & T

export type FooterLinkInternal = FooterBaseContent<{
  type: 'link-internal'
  name: string
  params?: Record<string, string>
  prefetch?: boolean
}>

export type FooterLink = FooterBaseContent<{
  url: string
  type: 'link' | 'link-external'
}>

export type FooterPaymentNetworks = FooterBaseContent<{
  type: 'payment-networks'
}>

export type FooterPaymentNetworksWithLink = FooterBaseContent<{
  type: 'payment-networks-with-link'
  url: string
}>

export type FooterIcons = Omit<
  FooterBaseContent<{
    type: 'icons'
    url: string
    style: 'icon' | 'iconSmall'
    icons: Array<{
      src: string
      name: string
      width: number
      height: number
    }>
  }>,
  'key'
>

export type FooterSocials = Omit<
  FooterBaseContent<{
    type: 'social-networks'
    networks: {
      twitter?: string
      linkedin?: string
      facebook?: string
      instagram?: string
    }
  }>,
  'key'
>

export type FooterLogos = Omit<
  FooterBaseContent<{
    type: 'logos'
    logos: Logo[]
  }>,
  'key'
>

export type FooterContent =
  | FooterLink
  | FooterLinkInternal
  | FooterPaymentNetworks
  | FooterPaymentNetworksWithLink
  | FooterIcons
  | FooterSocials
  | FooterLogos

export function isFooterLink(content: FooterContent): content is FooterLink {
  return content.type === 'link' || content.type === 'link-external'
}

export function isFooterLinkInternal(
  content: FooterContent,
): content is FooterLinkInternal {
  return content.type === 'link-internal'
}

export function isFooterPaymentNetworks(
  content: FooterContent,
): content is FooterPaymentNetworks {
  return content.type === 'payment-networks'
}

export function isFooterPaymentNetworksWithLink(
  content: FooterContent,
): content is FooterPaymentNetworksWithLink {
  return content.type === 'payment-networks-with-link'
}

export function isFooterIcons(content: FooterContent): content is FooterIcons {
  return content.type === 'icons'
}

export function isFooterSocials(
  content: FooterContent,
): content is FooterSocials {
  return content.type === 'social-networks'
}

export function isFooterLogos(content: FooterContent): content is FooterLogos {
  return content.type === 'logos'
}
